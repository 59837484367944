import React from 'react'
import { CCol, CFooter, CRow, CImage } from '@coreui/react'


const TheFooter = () => {
  return (
    <CFooter className='d-block bg-dark text-secondary'>
      <CRow>
        <CCol className='text-start'>
          <span className="me-1">Release 4.7 {process.env.BUILD_ID ? 'Build-' + (process.env.BUILD_ID).substring(0, 6) : ''}</span>
        </CCol>
        <CCol className='text-end'>
            <a href="https://www.lagna360.com" target="_blank" rel="noopener noreferrer" className='text-decoration-none text-secondary'>&copy; 2024</a>
        </CCol>
      </CRow>
      <CRow className='mt-4 mb-3'>
        <CCol className='text-center'>
          <a href="https://policies.google.com/terms"  target="_blank" rel="noopener noreferrer" className='text-decoration-none'>
            <CImage
              src='/images/RecaptchaLogo.svg'
              alt="recaptcha logo"
              height="32"
              className='d-inline me-2'
            /><span className='text-secondary'>Protected reCAPTCHA</span>
          </a>
        </CCol>
      </CRow>
    </CFooter>
  )
}

export default React.memo(TheFooter)